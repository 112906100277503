import settings from '@/settings'
import axios from 'axios'

/**
 * 中央儲值系統
 */
class Deposit {
  /**
   * 歷史資訊
   */
  history(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/depositsystem/history/${schoolId}`,
      {
        params: params
      }
    )
  }

  export(params) {
    return axios.post(
      `${settings.api.fullPath}/ems/depositsystem/history/export`,
      null,
      {
        params: params,
        responseType: 'blob'
      }
    )
  }
}

var deposit = new Deposit()
export default deposit

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :disabled="readonly"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRange"
        :label="label"
        :placeholder="placeholder"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :hide-details="hideDetails"
        readonly
        :solo="solo"
        :flat="flat"
        v-bind="attrs"
        v-on="on"
        class="date-picker-input"
        :rules="[
          (v) => !!v || '選擇時間區間',
          (v) => v.indexOf('~') > -1 || '請選擇時間結束日期'
        ]"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="datePicker"
      no-title
      scrollable
      color="primary"
      :range="range"
      :max="max"
      :min="min"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'InputDatePicker',
  mixins: [],
  components: {},
  props: {
    value: {},
    label: {
      type: String,
      default: '請選擇日期'
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      default: 'mdi-calendar'
    },
    prependInnerIcon: {},
    readonly: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    max: {
      type: String
    },
    min: {
      type: String
    },
    range: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      menu: null,
      datePicker: []
    }
  },
  computed: {
    dateRange() {
      if (this.datePicker.length === 0) return ''
      const dates = this.datePicker
        .slice()
        .sort(
          (a, b) =>
            parseFloat(moment(a).format('x'), 10) -
            parseFloat(moment(b).format('x'), 10)
        )
      this.$emit('input', dates.join('~'))
      return dates.join('~')
    }
  },
  watch: {
    value: function (val) {
      this.date = val
    },
    date: function (val) {
      this.commit()
    }
  },
  created: function () {},
  mounted: function () {
    this.date = this.value || null
  },
  destroyed() {},
  methods: {
    commit() {
      this.$emit('input', this.date)
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="pa-3">
    <v-row justify="end">
      <v-col cols="auto">
        <InputDatePicker
          label="請選擇時間區間"
          solo
          hide-details
          prepend-icon=""
          v-model="dateRange"
          :range="true"
        />
      </v-col>
      <v-col cols="auto">
        <v-select
          v-model="classId"
          :items="classItems"
          item-text="displayName"
          item-value="id"
          label="班級"
          solo
          hide-details
          clearable
        ></v-select>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="primary"
          class="px-8"
          large
          :loading="loadingExport"
          :disabled="!filterData.length || loadingExport"
          @click="exportReport"
        >
          匯出報表
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="deposit-list rounded-0"
      :headers="headers"
      :items="filterData"
      :items-per-page="filterData.length"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.emsdepositclass`]="{ item }">
        {{ classItemName[item.classroomId] }}
      </template>
      <template v-slot:[`item.emsdepositcardtype`]="{ item }">
        {{ cardTypeOptionName[item.cardType] }}
      </template>
      <template v-slot:[`item.emsdepositaction`]="{ item }">
        {{ depositActionOptionName[item.action] }}
      </template>
      <template v-slot:[`item.timestamp`]="{ item }">
        {{ moment(item.timestamp).format('YYYY-MM-DD HH:mm') }}
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputDatePicker from '@/components/InputDatePicker'
import Deposit from '@/api/ems/Deposit'
import fileDownload from 'js-file-download'

export default {
  name: 'SchoolDeposit',
  mixins: [],
  components: {
    InputDatePicker
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      overlay: false,
      dateRange: null,
      classId: null,
      query: {
        from: '2021-01-01',
        to: '2021-12-31'
      },
      headers: [
        {
          text: '班級',
          value: 'emsdepositclass',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '卡別',
          value: 'emsdepositcardtype',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '卡號',
          value: 'cardNumber',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '動作',
          value: 'emsdepositaction',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '加值前',
          value: 'changeValue',
          align: 'end',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '加值金額',
          value: 'addValue',
          align: 'end',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '加值後',
          value: 'valueAfterChange',
          align: 'end',
          class: 'ems-bluegrey--text subtitle-1'
        },
        {
          text: '變更日期',
          value: 'timestamp',
          align: 'center',
          class: 'ems-bluegrey--text subtitle-1'
        }
      ],
      items: [],
      loadingExport: false
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      typeOption: 'card/typeOption',
      actionOption: 'deposit/actionOption'
    }),
    depositActionOptionName() {
      if (!this.actionOption) {
        return {}
      }
      const option = {}
      this.actionOption.forEach((element) => {
        option[element.value] = element.text
      })
      return option
    },
    cardTypeOptionName() {
      if (!this.typeOption) {
        return {}
      }
      const option = {}
      this.typeOption.forEach((element) => {
        option[element.value] = element.name
      })
      return option
    },
    classItems() {
      let classList = [{ displayName: '全部教室', id: null }]
      if (this.pageData?.branch) {
        classList = classList.concat(this.pageData.branch)
      }
      return classList
    },
    classItemName() {
      if (!this.classItems) {
        return {}
      }
      const option = {}
      this.classItems.forEach((element) => {
        option[element.id] = element.name
      })
      return option
    },
    filterData() {
      if (!this.classId) {
        return this.items
      }
      return this.items.filter((item) => item.classroomId === this.classId)
    },
    exportParams() {
      return {
        schoolId: this.tagId,
        ...(this.classId && { classroomId: this.classId }),
        from: this.query.from,
        to: this.query.to
      }
    }
  },
  watch: {
    dateRange(val) {
      if (val.split('~').length >= 2) {
        this.query.from = moment(val.split('~')[0]).format('YYYY-MM-DD')
        this.query.to = moment(val.split('~')[1]).format('YYYY-MM-DD')
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.overlay = true
      Deposit.history(this.tagId, this.query)
        .then((response) => {
          console.log(response)
          this.items = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.overlay = false
        })
    },
    async exportReport() {
      try {
        this.loadingExport = true
        const response = await Deposit.export(this.exportParams)
        fileDownload(
          response.data,
          `儲值紀錄報表_${this.pageData.displayName}_${
            this.classId ? this.classItemName[this.classId] : '全部教室'
          }_${this.query.from}~${this.query.to}.csv`
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingExport = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-data-table.deposit-list::v-deep {
  background-color: transparent;
  table {
    border-collapse: separate;
    border-spacing: 0 1em;
    th,
    td {
      border-bottom: none !important;
    }
    td {
      background-color: #fff;
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
  }
}
</style>
